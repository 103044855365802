/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                function inXRange(currentX, element) {

                    var elementStartX = 0;
                    var elementEndX = element.outerWidth();

                    return (currentX > elementStartX && currentX < elementEndX);
                }

                function inYRange(currentY, element) {
                    var elementStartY = 0;
                    var elementEndY = element.outerHeight();

                    return (currentY > elementStartY && currentY < elementEndY);
                }

                var slider_carousel = $("#owl-slider");

                slider_carousel.owlCarousel({
                    items: 1, //1 item above 1000px browser width
                    autoplay: false,
                    autoplayTimeout: 10000,
                    dots: false,
                    loop: false,
                    itemsDesktop: [1000, 1], //1 item between 1000px and 901px
                    itemsDesktopSmall: [900, 1], //1 item betweem 900px and 601px
                    itemsTablet: [600, 1] //1 items between 600 and 0
                });

                $('#testimonial-slider').owlCarousel({
                    items: 1, //1 item above 1000px browser width
                    autoplay: true,
                    autoplayTimeout: 10000,
                    dots: true,
                    loop: true,
                    itemsDesktop: [1000, 1], //1 item between 1000px and 901px
                    itemsDesktopSmall: [900, 1], //1 item betweem 900px and 601px
                    itemsTablet: [600, 1] //1 items between 600 and 0
                });

                //TODO validate mail chimp form through javascript

                //if ($('.mc4wp-error').length > 0) {
                //    $('.newsletter-content').show();
                //}

                $(document).on('click', '.read-more-sponsor-content', function (e) {
                    e.preventDefault();
                    var sponsorId = $(this).attr('data-sponsor-id');

                    $('#excerpt_' + sponsorId).hide();
                    $('#full_content_' + sponsorId).show();
                });

                $(document).on('click', '.read-less-sponsor-content', function (e) {
                    e.preventDefault();
                    var sponsorId = $(this).attr('data-sponsor-id');

                    $('#full_content_' + sponsorId).hide();
                    $('#excerpt_' + sponsorId).show();
                });

                $(document).on('click', '.dm-sidebar .menu-item a', function (e) {

                    if ($(this).attr('href') == null || $(this).attr('href') == '' || $(this).attr('href') == '#') {

                        e.preventDefault();

                        var firstUl = $(this).parent().find('>ul');

                        $(this).parent().find('ul').hide();
                        firstUl.show();
                    }
                });

                $(document).on('click', '#new-letter-link', function (e) {
                    e.preventDefault();
                    $('.newsletter-content').show();
                });

                $(document).on('click', '.newsletter-content', function (e) {

                    var innerDialog = $(this).find('.newsletter-content-inner');

                    if (innerDialog.length > 0) {
                        if (!(inXRange(e.offsetX, innerDialog) && inYRange(e.offsetY, innerDialog))) {
                            $('.newsletter-content').hide();
                        }
                    }

                });

                //var siteurl = $('#siteurl').val();
                //var siteurl = 'https://us15.api.mailchimp.com/3.0';

               /* $('#mc4wp-form-1').submit(function () {
                    $("#message").html("Adding your email address...");

                    var listId = '474e0d7bcd';
                    var apiKey = '5bd41a06539033320993907718b3998d-us15';


                    $.ajax({
                        url: siteurl + '/lists/' + listId + '/signup-forms', // proper url to your "store-address.php" file
                        // url: siteurl, // current home page
                        type: 'POST', // <- IMPORTANT
                        data: $('#mc4wp-form-1').serialize() + '&ajax=true',
                        beforeSend: function (xhr){
                            xhr.setRequestHeader ("Authorization", "Basic " + btoa('diyen' + ":" + apiKey));
                        },
                        success: function (msg) {
                            /\*var message = $.parseJSON(msg),
                             result = '';
                             if (message.status === 'pending') { // success
                             result = 'Success!  Please click the confirmation link that will be emailed to you shortly.';
                             } else { // error
                             result = 'Error: ' + message.detail;
                             }
                             $('#message').html(result); // display the message
                            *\/
                        }
                    });
                    return false;
                });*/

                var bootstrapCollapsibleContent = $(".dm-more-content");

                bootstrapCollapsibleContent.on('hide.bs.collapse', function() {
                    var id = $(this).attr('id');
                    $('.dm-more-btn[data-target="#' + id + '"]').text('More');
                });

                bootstrapCollapsibleContent.on('show.bs.collapse', function() {
                    var id = $(this).attr('id');
                    $('.dm-more-btn[data-target="#' + id + '"]').text('Less');
                });
            },
            animateSlideContent: function () {
                $('.slider-title').effect("slide", 700);
                $('.slider-content').effect("slide", 1000);
                $('.slider-buttons').effect("slide", 1200);

                // $('.yourclass').toggle("slide", {direction: "right"}, 1000);
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
